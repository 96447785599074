import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import EnhancedTableHead from './TableHead'
import { Data } from '../../pages/dashboard'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../constants/colors'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { RootState } from '../../redux/reducers/rootReducers'
import { onFetchWorkouts as onFetchWorkoutsAction } from '../../redux/actions'
import { withErrorHandler } from '../../components/errorHandler'
import { PageRendererProps } from 'gatsby'
import { DateTime } from 'luxon'
import { imagePlaceholder } from '../../components/WorkoutsRow/Components/WorkoutItem'

export const Profile = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export type Order = 'asc' | 'desc'

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

export interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Video',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'published',
    numeric: false,
    disablePadding: false,
    label: 'Published',
  },
  {
    id: 'length',
    numeric: false,
    disablePadding: false,
    label: 'Length',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tag',
  },
  {
    id: 'views',
    numeric: false,
    disablePadding: false,
    label: 'Views',
  },
  {
    id: 'secondsWatched',
    numeric: false,
    disablePadding: false,
    label: 'Minutes Watched',
  },
]

function UploadsTable(props: Props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { workouts, onFetchWorkouts } = props

  useEffect(() => {
    onFetchWorkouts()
  }, [onFetchWorkouts])

  const dense = false

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const data = workouts

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

  return (
    <ThemeProvider theme={theme}>
      {data.length === 0 ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => null}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                  headCells={headCells}
                />
                <TableBody>
                  {/* @ts-ignore */}
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                      >
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          align="left"
                          padding="default"
                        >
                          <Profile>
                            <img
                              src={row.thumbnail || imagePlaceholder}
                              style={{
                                width: 110,
                                borderRadius: 6,
                                margin: 0,
                                height: 70,
                                objectFit: 'cover',
                              }}
                            />

                            <div style={{ marginRight: 20 }} />

                            <div style={{ maxWidth: 300 }}>
                              {row.title}
                              <br />
                              <span style={{ color: 'rgba(0,0,0,.4)' }}>
                                {row.description &&
                                  row.description.substring(0, 70)}
                              </span>
                            </div>
                          </Profile>
                        </TableCell>
                        <TableCell align="left">
                          {DateTime.fromISO(row.createdAt).toFormat('ff')}
                        </TableCell>

                        <TableCell align="left">
                          {row.published ? 'Published' : 'Not Published'}
                        </TableCell>
                        <TableCell align="left">{row.length}</TableCell>
                        <TableCell align="left">{row.tags[0]}</TableCell>
                        <TableCell align="center">{row.views}</TableCell>
                        <TableCell align="center">
                          {Math.round(row.secondsWatched / 60)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </ThemeProvider>
  )
}

const mapStateToProps = (state: RootState) => ({
  workouts: state.entities.workoutsReducer.workouts,
})

const mapDispatchToProps = {
  onFetchWorkouts: onFetchWorkoutsAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PageRendererProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(UploadsTable))
