import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import EnhancedTableHead from './TableHead'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../constants/colors'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ILiveWorkout, LiveConfig, SignUpType } from '../../types'
import {
  fetchAllLiveWorkouts,
  fetchLiveConfig,
} from '../../util/fetch/workouts'
import { DateTime } from 'luxon'

const getSymbolFromCurrency = require('currency-symbol-map')

type Data = ILiveWorkout

export const Profile = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export type Order = 'asc' | 'desc'

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

export interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Live Class',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'length',
    numeric: false,
    disablePadding: false,
    label: 'Length',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tag',
  },
  {
    id: 'usersAttendingLength',
    numeric: false,
    disablePadding: false,
    label: 'Sign Ups',
  },
  {
    id: 'classPrice',
    numeric: false,
    disablePadding: false,
    label: 'Class Price',
  },
  {
    id: 'dropInAmount',
    numeric: false,
    disablePadding: false,
    label: 'Drop-Ins',
  },
]

export default function LiveClassesTable({}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof ILiveWorkout>('title')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [workouts, setWorkouts] = useState<ILiveWorkout[]>([])
  const [liveConfig, setLiveConfig] = useState<LiveConfig | null>(null)

  useEffect(() => {
    const getWorkouts = async () => {
      const response = await fetchAllLiveWorkouts()
      setWorkouts(response)
      // setLoading(false)
    }
    const getLiveConfig = async () => {
      const response = await fetchLiveConfig()
      setLiveConfig(response)
    }
    getLiveConfig()
    getWorkouts()
  }, [])

  const dense = false

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getClassPrice = (priceId: string) => {
    const priceObj = liveConfig.liveWorkoutPrices.filter(
      price => price.priceId === priceId,
    )[0]
    if (priceObj) {
      return priceObj.amount / 100
    } else {
      return 0
    }
  }

  const getDropInAmount = (lw: ILiveWorkout) => {
    const classPrice = getClassPrice(lw.priceId)
    return lw.usersAttending.reduce((acc: number, curr: any) => {
      if (curr.signUpType === SignUpType.DROP_IN) {
        return acc + classPrice
      }
      return acc
    }, 0)
  }

  const currency =
    liveConfig &&
    getSymbolFromCurrency(liveConfig.liveWorkoutPrices[0].currency)

  const data = liveConfig
    ? workouts.map(item => ({
        ...item,
        usersAttendingLength: item.usersAttending.length,
        classPrice: getClassPrice(item.priceId),
        dropInAmount: getDropInAmount(item),
      }))
    : []

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

  return (
    <ThemeProvider theme={theme}>
      {data.length === 0 ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={() => null}
                  // @ts-ignore
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                  headCells={headCells}
                />
                <TableBody>
                  {/* @ts-ignore */}
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                      >
                        <TableCell
                          component="th"
                          // id={labelId}
                          scope="row"
                          align="left"
                          padding="default"
                        >
                          <Profile>
                            {row.thumbnail && (
                              <img
                                src={row.thumbnail}
                                style={{
                                  width: 110,
                                  borderRadius: 6,
                                  margin: 0,
                                  height: 70,
                                  objectFit: 'cover',
                                }}
                              />
                            )}
                            {row.thumbnail && (
                              <div style={{ marginRight: 20 }} />
                            )}
                            <div>
                              {row.title}
                              <br />
                              <span style={{ color: 'rgba(0,0,0,.4)' }}>
                                {row.description &&
                                  row.description.substring(0, 100)}
                              </span>
                            </div>
                          </Profile>
                        </TableCell>
                        <TableCell align="left">
                          {DateTime.fromISO(row.startDate).toFormat('ff')}
                        </TableCell>
                        <TableCell align="left">{row.length}</TableCell>
                        <TableCell align="left">{row.type[0]}</TableCell>
                        <TableCell align="left">
                          {row.usersAttendingLength}
                        </TableCell>
                        <TableCell align="left">
                          {currency}
                          {row.classPrice}
                        </TableCell>
                        <TableCell align="left">
                          {currency}
                          {row.dropInAmount}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </ThemeProvider>
  )
}
