import React, { useState } from 'react'
import { theme } from '../../../constants/colors'
import { ThemeProvider } from '@material-ui/core/styles'
import Layout from '../../../templates/LiveLayout'
import { withErrorHandler } from '../../../components/errorHandler'
import { PageRendererProps } from 'gatsby'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import LiveClassesTable from '../../../components/stats/LiveClassesTable'
import UploadsTable from '../../../components/stats/UploadsTable'

// @ts-ignore
import AnalyticsPlaceholder from '../../../images/analyticsPlaceholder.png'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
`
const Tabs = styled.div`
  display: flex;
  width: 100%;
  max-width: 1262px;
  margin-bottom: 30px;
`

const WidthWrap = styled.div`
  width: 1250px;
`

const buttonStyle = {
  textTransform: 'capitalize',
  fontSize: 18,
  marginRight: 10,
}

type Props = PageRendererProps

const DashboardPageTrainer = ({ location }: Props) => {
  const [activeTab, setActiveTab] = useState('uploads')
  return (
    <ThemeProvider theme={theme}>
      <Layout title="Analytics" location={location}>
        <div style={{ marginTop: -40 }} />
        <Container>
          <Tabs>
            <Button
              variant={activeTab === 'uploads' ? 'outlined' : 'text'}
              color="primary"
              // @ts-ignore
              style={buttonStyle}
              onClick={() => setActiveTab('uploads')}
            >
              Uploads
            </Button>
            <Button
              variant={activeTab === 'live' ? 'outlined' : 'text'}
              color="primary"
              // @ts-ignore
              style={buttonStyle}
              onClick={() => setActiveTab('live')}
            >
              Live
            </Button>
          </Tabs>
          <WidthWrap>
            {activeTab === 'live' && <LiveClassesTable />}
            {activeTab === 'uploads' && <UploadsTable />}
          </WidthWrap>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default withErrorHandler(DashboardPageTrainer)
